import * as React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "../components/BlogLayout";
import Seo from "../components/seo";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";

type DataProps = {
  site: {
    siteMetadata: {
      title: string;
    };
  };
};

const NotFoundPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location}>
      <Seo title="서도성, 號令君子" description="작전? 통제? 다 내게 맡겨라" />
      <Heading mb={8} fontFamily="Do Hyeon" size="2xl">
        서도성, 號令君子
      </Heading>
      <Box
        maxW="4xl"
        p={4}
        textColor="gray.800"
        background="white"
        borderRadius="lg"
        shadow="base"
      >
        <Heading
          size="xl"
          textAlign="left"
          textColor="gray.600"
          fontFamily="Nanum Gothic"
        >
          "
        </Heading>
        <Text
          paddingX={4}
          fontSize="lg"
          textAlign="center"
          textColor="gray.600"
        >
          개발자는 늘 초심을 지키며 기본에 충실하라
        </Text>

        <Heading
          size="xl"
          textAlign="right"
          textColor="gray.600"
          fontFamily="Nanum Gothic"
        >
          "
        </Heading>
      </Box>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
